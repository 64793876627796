import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Helmet } from 'react-helmet';
import { Box } from 'grommet';
import styled from 'styled-components';
import Layout from '../components/Layout/Layout';
import NukaCarousel from '../components/Carousel';
import RevertPadding from '../components/RevertPadding';
import IsSmaller from '../components/IsSmaller';

export const frontmatter = {
  title: 'Über uns',
  menu: 2,
};

export default () => {
  const { img01, img02, img03 } = useStaticQuery(graphql`
    {
      img01: file(relativePath: { eq: "ueber-uns/ueber-uns-01.jpg" }) {
        id
        relativePath
        childImageSharp {
          gatsbyImageData(width: 550, height: 400, layout: CONSTRAINED)
        }
      }
      img02: file(relativePath: { eq: "ueber-uns/ueber-uns-02.jpg" }) {
        id
        relativePath
        childImageSharp {
          gatsbyImageData(width: 550, height: 400, layout: CONSTRAINED)
        }
      }
      img03: file(relativePath: { eq: "ueber-uns/ueber-uns-03.jpg" }) {
        id
        relativePath
        childImageSharp {
          gatsbyImageData(width: 550, height: 400, layout: CONSTRAINED)
        }
      }
    }
  `);

  return (
    <Layout>
      <Helmet>
        <title>Über uns</title>
      </Helmet>
      <h1>Über uns</h1>
      <p>
        Gegründet im Jahre 1741 ist die Hirsch-Apotheke die älteste und
        traditionsreichste Apotheke im Herzen der Korbacher Altstadt. Seit 275
        Jahren versorgen wir in Korbach unsere Kunden mit Arznei-, Hilfs- und
        Verbandmitteln, stellen in unserem eigenen Labor individuelle
        Arzneimittel her und beraten zu deren Anwendung, Wirkungsweise, Neben-
        und Wechselwirkungen. Wir passen Kompressionsstrümpfe und Bandagen an,
        besorgen internationale Medikamente und bieten für unsere Stammkunden
        eine Patientendatenbank an, die ein optimales Medikationsmanagement
        ermöglicht.
      </p>
      <p>
        Bei all unseren Dienstleistungen pflegen wir eine partnerschaftliche
        Zusammenarbeit mit allen Beteiligten im Gesundheitswesen. Wir beraten
        und beliefern Arztpraxen, Heime, Pflegedienste, Podologen,
        Physiotherapeuten und andere Berufsgruppen.
      </p>
      <p>
        Im Mittelpunkt unserer Tätigkeit steht immer der Patient bzw. der Kunde,
        dessen gesundheitliche Probleme durch unsere Tätigkeit umfassend gelöst
        werden sollen. Dazu verfügen unsere Mitarbeiterinnen auch über spezielle
        Fortbildungen, z.B. im Bereich Kompressionsstrümpfe, Bandagen,
        Homöopathie oder Mineralstoffberatung. Unser Warenlager wird ständig
        aktualisiert. So stellen wir sicher, dass jeder Patient sein benötigtes
        Medikament zum richtigen Zeitpunkt erhält. Sollte ein Mittel einmal
        nicht auf Lager sein, wird es in der Regel innerhalb von wenigen Stunden
        besorgt.
      </p>
      <p>
        Für alle Fragen rund um Arzneimittel haben wir stets ein offenes Ohr und
        sind für Sie persönlich, telefonisch sowie per Fax oder E-Mail
        erreichbar.
      </p>
      <IsSmaller than={550}>
        {({ isSmaller }) =>
          isSmaller ? (
            <RevertPadding>
              <NukaCarousel mobileBreakpoint={550} autoPlayInterval={5000}>
                <GatsbyImage
                  image={img01.childImageSharp.gatsbyImageData}
                  alt=""
                />
                <GatsbyImage
                  image={img02.childImageSharp.gatsbyImageData}
                  alt=""
                />
                <GatsbyImage
                  image={img03.childImageSharp.gatsbyImageData}
                  alt=""
                />
              </NukaCarousel>
            </RevertPadding>
          ) : (
            <Box direction="row" gap="xsmall">
              <ImgBox>
                <GatsbyImage
                  image={img01.childImageSharp.gatsbyImageData}
                  alt=""
                />
              </ImgBox>
              <ImgBox>
                <GatsbyImage
                  image={img02.childImageSharp.gatsbyImageData}
                  alt=""
                />
              </ImgBox>
              <ImgBox>
                <GatsbyImage
                  image={img03.childImageSharp.gatsbyImageData}
                  alt=""
                />
              </ImgBox>
            </Box>
          )
        }
      </IsSmaller>
    </Layout>
  );
};

const ImgBox = styled.div`
  flex: 1 1 auto;
`;
